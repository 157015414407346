import {
  actionChannel,
  all,
  call,
  put,
  select,
  take,
} from "redux-saga/effects";

import * as conversationActions from "@/actions/conversationActions";
import * as hookActions from "@/actions/hookActions";
import EditorHelper from "@/components/RichTextEditor/EditorHelper";
import * as conversationEventDefinitions from "@/definitions/conversation/event/conversationEventDefinitions";
import { SLASH_COMMAND_WHATSAPP_HSM_FRAGMENT } from "@/definitions/whatsappHsmTranslatedTemplate/slashCommandWhatsappHsmTranslatedTemplatesDefinitions";
import { conversationEventTypeEnum } from "@/enums/conversation";
import {
  bodyRawTypeEnum,
  entityTypeEnum,
  richTextEditorNameEnum,
} from "@/enums/editor";
import { fileUploadStatusEnum } from "@/enums/fileEnum";
import { hsmTemplateHeaderTypeEnum } from "@/enums/hsmEnum";
import { messageStatusEnum } from "@/enums/messageEnum";
import { slashCommandKeywordEnum } from "@/enums/slashCommandEnums";
import { severityEnum } from "@/enums/styleVariantEnum";
import { dataObjectTypenameEnum } from "@/enums/typename";
import * as inboxPageMutations from "@/mutations/inboxPageMutations";
import * as mediaSelectors from "@/selectors/mediaSelectors";
import * as hsmUtils from "@/utils/hsmUtils";
import * as inboxPageUtils from "@/utils/inboxPageUtils";
import * as sagaUtils from "@/utils/sagaUtils";
import { getInputParams } from "@/utils/slashCommandUtils";

const handleConversationMessageError = ({
  client,
  initialConversationEvent,
  error,
}) => {
  const data = {
    status: messageStatusEnum.failed,
    failedErrorCode: error.graphQLErrors?.[0]?.code || null,
    failedErrorMessage:
      error.graphQLErrors?.[0]?.message ||
      error.response?.data?.media_file?.[0] ||
      error.message ||
      "Undefined error",
  };

  try {
    /* Update the dummy event in the cache with error info if sending the message fails */
    const { id } = initialConversationEvent.actionObject;
    client.writeFragment({
      id: `${dataObjectTypenameEnum.conversationMessageObject}:${id}`,
      fragment:
        conversationEventDefinitions.CONVERSATION_MESSAGE_ERROR_FRAGMENT,
      data,
    });
  } catch (error) {
    /* Error could occur if event is not in cache for whatever reason. Do nothing if that happens */
    return;
  }
};

function* handleConversationNoteError({
  client,
  initialConversationEvent,
  error,
  onSetAppSnackbarProps,
}) {
  try {
    /* Try to remove the dummy event from the cache */
    const { id } = initialConversationEvent;
    client.cache.evict({
      id: `${dataObjectTypenameEnum.relatedEventObject}:${id}`,
    });
    client.cache.gc();

    yield onSetAppSnackbarProps({
      message: error.message,
      AlertProps: { severity: severityEnum.error },
    });
  } catch (error) {
    /* Error could occur if event is not in cache for whatever reason. Do nothing if that happens */
    return;
  }
}

function* handleMediaUploadError({
  src,
  client,
  initialConversationEvent,
  onSetAppSnackbarProps,
}) {
  const { eventType } = initialConversationEvent;

  const { error } = yield select(
    mediaSelectors.mediaUpload({
      key: src,
      editorName: richTextEditorNameEnum.chat,
    }),
  );

  if (eventType === conversationEventTypeEnum.message) {
    handleConversationMessageError({
      client,
      initialConversationEvent,
      error,
    });
  } else if (eventType === conversationEventTypeEnum.conversationNote) {
    yield handleConversationNoteError({
      client,
      initialConversationEvent,
      error,
      onSetAppSnackbarProps,
    });
  }
}

function* checkIfMediaUploadIsCompleted({ src }) {
  const status = yield select(
    mediaSelectors.mediaStatus({
      key: src,
      editorName: richTextEditorNameEnum.chat,
    }),
  );

  return [fileUploadStatusEnum.success, fileUploadStatusEnum.failed].includes(
    status,
  );
}

function* getMediaUuid(src) {
  /* Wait for media upload to complete */
  yield call(sagaUtils.waitFor, {
    func: checkIfMediaUploadIsCompleted,
    args: { src },
  });

  /* Retrieve the uuid for the media (could be undefined if upload failed) */
  const uuid = yield select(
    mediaSelectors.mediaUuid({
      key: src,
      editorName: richTextEditorNameEnum.chat,
    }),
  );

  return uuid;
}

function* getSlashCommandContentInput({
  client,
  rawEditorStateData,
  selectedSlashCommand,
  initialConversationEvent,
  onSetAppSnackbarProps,
}) {
  const returnObj = { whatsappHsmContentInput: null };

  const editorState = EditorHelper.convertEditorStateFromRaw({
    rawEditorState: rawEditorStateData,
  });

  const { keywordArray, inputParamsArray } = getInputParams({
    editorState,
    selectedCommand: selectedSlashCommand,
  });

  if (keywordArray[0] === slashCommandKeywordEnum.whatsAppHsm) {
    const whatsappHsmTranslatedTemplate = client.readFragment({
      id: `${dataObjectTypenameEnum.whatsappHSMTranslatedTemplateObject}:${selectedSlashCommand.id}`,
      fragment: SLASH_COMMAND_WHATSAPP_HSM_FRAGMENT,
    });

    /* Early return if we could not find the template in the cache for some reason */
    if (!whatsappHsmTranslatedTemplate) return returnObj;

    const { headerType, headerTextParams, bodyTextParams, buttonUrlParams } =
      whatsappHsmTranslatedTemplate;
    const paramNameToValueMap = hsmUtils.getHsmParamNameValueMap({
      whatsappHsmTranslatedTemplate,
      inputParamsArray,
    });

    let headerVariables = [];
    if (headerType === hsmTemplateHeaderTypeEnum.TE) {
      headerVariables = headerTextParams.map(
        (param) => paramNameToValueMap[param.hint],
      );
    } else if (hsmUtils.checkIsHsmWithMediaHeader(headerType)) {
      const { src } = selectedSlashCommand.hsmHeaderMediaData;
      const mediaUuid = yield getMediaUuid(src);

      if (mediaUuid) {
        headerVariables = [mediaUuid];
      } else {
        handleMediaUploadError({
          src,
          client,
          initialConversationEvent,
          onSetAppSnackbarProps,
        });
        return returnObj;
      }
    }

    const bodyVariables = bodyTextParams.map(
      (param) => paramNameToValueMap[param.hint],
    );

    const buttonVariables = buttonUrlParams.map(
      (param) => paramNameToValueMap[param.hint],
    );

    returnObj.whatsappHsmContentInput = {
      keyword: keywordArray[1],
      variables: [...headerVariables, ...bodyVariables, ...buttonVariables],
    };
  }

  return returnObj;
}

function* getTextAndMediaContentInput({
  rawEditorStateData,
  client,
  initialConversationEvent,
  onSetAppSnackbarProps,
}) {
  const returnObj = {
    textContentInputObject: null,
    mediaContentInputObject: null,
  };

  /* Get the data for the media entity if it exists */
  const mediaEntityData = Object.values(rawEditorStateData.entityMap).find(
    (entity) => entity.type === entityTypeEnum.media,
  )?.data;

  /* Get modified raw data without media entity and with entities transformed to how backend wants them */
  const modifiedRawData = EditorHelper.prepareModifiedRawDraftJsDataForBackend({
    rawEditorState: rawEditorStateData,
  });
  const rawJSONData = JSON.stringify(modifiedRawData);

  if (mediaEntityData) {
    const { src, uuid, type } = mediaEntityData;

    /* Get the uuid for the media that exists */
    const mediaUuid = yield uuid || getMediaUuid(src);

    if (mediaUuid) {
      const mediaMessageType =
        inboxPageUtils.getMediaMessageContentTypeFromFileType(type);

      returnObj.mediaContentInputObject = {
        media: mediaUuid,
        mediaMessageType,
        captionRawType: bodyRawTypeEnum.draftJs,
        captionRawData: rawJSONData,
      };
    } else {
      yield handleMediaUploadError({
        src,
        client,
        initialConversationEvent,
        onSetAppSnackbarProps,
      });
    }
  } else {
    returnObj.textContentInputObject = {
      bodyRawType: bodyRawTypeEnum.draftJs,
      bodyRawData: rawJSONData,
    };
  }

  return returnObj;
}

const updateCacheAfterSendingSuccess = ({
  cache,
  initialConversationEvent,
  data,
  fragment,
}) => {
  const { id } = initialConversationEvent;
  const newActionObjectRef = cache.writeFragment({ fragment, data });

  if (!newActionObjectRef) return;

  cache.modify({
    id: `${dataObjectTypenameEnum.relatedEventObject}:${id}`,
    fields: { actionObject: () => newActionObjectRef },
  });
};

function* createConversationMessage({
  client,
  conversationId,
  messagingProviderAccountId,
  messagingProviderContactId,
  initialConversationEvent,
  rawEditorStateData,
  selectedSlashCommand,
  onSetAppSnackbarProps,
}) {
  const input = {
    conversation: conversationId,
    messagingProviderAccount: messagingProviderAccountId,
    recipientMessagingProviderContact: messagingProviderContactId,
    clientReference: initialConversationEvent.actionObject.clientReference,
  };

  /* Get the appropriate content object for the conversation message  */
  if (selectedSlashCommand) {
    const { whatsappHsmContentInput } = yield getSlashCommandContentInput({
      client,
      rawEditorStateData,
      selectedSlashCommand,
      initialConversationEvent,
      onSetAppSnackbarProps,
    });

    if (whatsappHsmContentInput) {
      input.whatsappHsmConversationMessage = whatsappHsmContentInput;
    } else {
      /* Return early and ignore the rest if no content object is returned for the message */
      return;
    }
  } else {
    const { textContentInputObject, mediaContentInputObject } =
      yield getTextAndMediaContentInput({
        rawEditorStateData,
        client,
        initialConversationEvent,
        onSetAppSnackbarProps,
      });

    if (textContentInputObject) {
      input.textConversationMessage = textContentInputObject;
    } else if (mediaContentInputObject) {
      input.mediaConversationMessage = mediaContentInputObject;
    } else {
      /* Return early and ignore the rest if no content object is returned for the message */
      return;
    }
  }

  try {
    /* Run mutation to create conversation message */
    const response = yield client.mutate({
      mutation: inboxPageMutations.CREATE_CONVERSATION_MESSAGE,
      variables: { input },
      update: (cache, { data }) => {
        /* Replace dummy action object we have in the cache with real one */
        updateCacheAfterSendingSuccess({
          cache,
          initialConversationEvent,
          data: data.createConversationMessage.instance,
          fragment:
            conversationEventDefinitions.NEW_CONVERSATION_MESSAGE_FRAGMENT,
        });
      },
    });

    /* Dispatch event */
    yield put({
      type: hookActions.MESSAGE_SENT,
      data: {
        conversationMessageId:
          response?.data?.createConversationMessage?.instance?.id,
      },
    });
  } catch (error) {
    handleConversationMessageError({ client, initialConversationEvent, error });
  }
}

function* createLandingPageDocumentShortLink({
  client,
  conversationId,
  messagingProviderAccountId,
  messagingProviderContactId,
  initialConversationEvent,
  selectedSlashCommand,
}) {
  try {
    const input = {
      landingPageDocumentId: selectedSlashCommand.id,
      clientReference: initialConversationEvent.actionObject.clientReference,
      conversationId,
      messagingProviderAccountId,
      messagingProviderContactId,
    };

    const response = yield client.mutate({
      mutation: inboxPageMutations.SEND_LANDING_PAGE_DOCUMENT_SHORT_LINK,
      variables: { input },
      update: (cache, { data }) => {
        /* Replace dummy action object we have in the cache with real one */
        updateCacheAfterSendingSuccess({
          cache,
          initialConversationEvent,
          data: data.sendLandingPageDocumentShortLinkMutation
            .conversationMessage,
          fragment:
            conversationEventDefinitions.NEW_CONVERSATION_MESSAGE_FRAGMENT,
        });
      },
    });

    /* Dispatch event */
    yield put({
      type: hookActions.MESSAGE_SENT,
      data: {
        conversationMessageId:
          response?.data?.createConversationMessage?.instance?.id,
      },
    });
  } catch (error) {
    handleConversationMessageError({ client, initialConversationEvent, error });
  }
}

function* sendConversationMessage(action) {
  const { rawEditorStateData, selectedSlashCommand } = action;

  if (!selectedSlashCommand) {
    yield createConversationMessage(action);
    return;
  }

  const editorState = EditorHelper.convertEditorStateFromRaw({
    rawEditorState: rawEditorStateData,
  });

  const { keywordArray } = getInputParams({
    editorState,
    selectedCommand: selectedSlashCommand,
  });

  if (keywordArray[0] === slashCommandKeywordEnum.content) {
    yield createLandingPageDocumentShortLink(action);
  } else {
    yield createConversationMessage(action);
  }
}

function* sendConversationNote({
  client,
  conversationId,
  initialConversationEvent,
  rawEditorStateData,
  onSetAppSnackbarProps,
}) {
  const input = {
    clientReference: initialConversationEvent.actionObject.clientReference,
    conversation: conversationId,
  };

  /* Get the appropriate content object for the conversation note  */
  const { textContentInputObject, mediaContentInputObject } =
    yield getTextAndMediaContentInput({
      rawEditorStateData,
      client,
      initialConversationEvent,
      onSetAppSnackbarProps,
    });

  if (textContentInputObject) {
    input.textConversationNote = textContentInputObject;
  } else if (mediaContentInputObject) {
    input.mediaConversationNote = mediaContentInputObject;
  } else {
    /* Return early and ignore the rest if no content object is returned for the note */
    return;
  }

  try {
    /* Run mutation to create conversation note */
    yield client.mutate({
      mutation: inboxPageMutations.CREATE_CONVERSATION_NOTE,
      variables: { input },
      update: (cache, { data: { createConversationNote } }) => {
        /* Replace dummy action object we have in the cache with real one */
        updateCacheAfterSendingSuccess({
          cache,
          initialConversationEvent,
          data: createConversationNote.instance,
          fragment: conversationEventDefinitions.NEW_CONVERSATION_NOTE_FRAGMENT,
        });
      },
    });
  } catch (error) {
    yield handleConversationNoteError({
      client,
      initialConversationEvent,
      error,
      onSetAppSnackbarProps,
    });
  }
}

function* watchSendMessagesAndNotes() {
  /* Take all actions relating to sending messages an notes */
  const channel = yield actionChannel([
    conversationActions.SEND_CONVERSATION_MESSAGE,
    conversationActions.SEND_CONVERSATION_NOTE,
  ]);

  while (true) {
    const action = yield take(channel);
    const { type } = action;

    if (type === conversationActions.SEND_CONVERSATION_MESSAGE) {
      yield sendConversationMessage(action);
    } else {
      yield sendConversationNote(action);
    }
  }
}

export default function* conversationSaga() {
  yield all([watchSendMessagesAndNotes()]);
}
