import { Typography, withStyles } from "@material-ui/core";
import React from "react";

const InfoFieldTypography = ({
  children,
  isValue = false,
  fontSize = "1rem",
  marginLeft = "10px",
  fontWeight = "normal",
  color = null,
  ...otherProps
}) => {
  const InfoField = withStyles((theme) => ({
    root: {
      fontSize,
      fontWeight,
      marginLeft: (isValue && marginLeft) || "0px",
      color: color || theme.palette.textPrimary.main,
    },
  }))(Typography);

  return (
    <InfoField variant="caption" gutterBottom {...otherProps}>
      {children}
    </InfoField>
  );
};

export default InfoFieldTypography;
