import { ClickAwayListener, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";

import EditIcon from "@/assets/images/icon-edit.svg";
import InfoFieldTypography from "@/components/InfoFieldTypography";
import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import hexTransparencyEnum from "@/enums/hexTransparencyEnum";
import { tagTypeEnum } from "@/enums/tagEnum";
import permissionEnum from "@/helpers/PermissionEnumHelper";
import withTags from "@/hoc/withTags";
import { getValueStringFromObjectOrList } from "@/utils/commonUtils";
import { areContentTypeTagsEqual } from "@/utils/inboxPageMemoUtils";
import TagSelector from "./TagSelector";

const useStyles = makeStyles((theme) => ({
  editTagButtonContainer: {
    display: "flex",
    width: "100%",
    "& > :first-child": {
      width: "100%",
    },
  },

  editTagButton: {
    width: "100%",
    borderRadius: "5px",
    padding: "6px 3px",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&.canUseTag": {
      cursor: "pointer",
      "&:hover": {
        background: `${theme.palette.grey[1450]}${hexTransparencyEnum[8]} 0% 0% no-repeat padding-box`,
      },

      "&:hover $editIcon": {
        visibility: "visible",
      },
    },
  },

  tagText: {
    lineHeight: "1.4",
    "& .placeholder": {
      color: theme.palette.grey[1450],
      fontStyle: "italic",
      fontSize: "0.85rem",
    },
    "&.isTagEmpty": {
      border: `1px solid ${theme.palette.grey[405]}`,
      borderRadius: "4px",
      opacity: "1",
      padding: "0px 6px 0px 6px",
    },
  },

  editIcon: {
    minWidth: "12px",
    maxWidth: "12px",
    visibility: "hidden",
  },

  tagSelectorContainer: {
    margin: "0px 0px 4.55px 10px",
    fontSize: "1rem",
  },
}));

const EditTagButton = ({ contentType = tagTypeEnum.CONTACT, tagProps }) => {
  const classes = useStyles();
  const [isEditTags, setIsEditTags] = useState(false);

  const { userPermissionsObject } = useAuthenticatedUserContext();
  const canUseTag = userPermissionsObject.hasPermission(permissionEnum.USE_TAG);

  const { selected, onCreate, onDelete, onLoadOptions } = tagProps;

  const handleOpenSelector = () => {
    if (!canUseTag) return;

    setIsEditTags(true);
  };

  const handleCloseSelector = (event) => {
    if (event.key === "Escape") setIsEditTags(false);
    if (event.type === "click") setIsEditTags(false);
  };

  const tagsString = getValueStringFromObjectOrList({
    data: selected,
    stringKey: "label",
    noDataValue: (
      <span>
        +<span className="placeholder"> Add contact tag</span>
      </span>
    ),
  });

  const isTagEmpty = selected.length === 0;

  return (
    <ClickAwayListener onClickAway={handleCloseSelector}>
      <div
        tabIndex={0}
        className={classes.editTagButtonContainer}
        onKeyDown={handleCloseSelector}
      >
        {!isEditTags && (
          <InfoFieldTypography
            isValue
            marginLeft="7px"
            className={clsx(classes.editTagButton, { canUseTag })}
            gutterBottom={false}
            onClick={handleOpenSelector}
          >
            <span className={clsx(classes.tagText, { isTagEmpty })}>
              {tagsString}
            </span>
            <EditIcon width="12px" className={classes.editIcon} />
          </InfoFieldTypography>
        )}

        {isEditTags && (
          <div className={classes.tagSelectorContainer}>
            <TagSelector
              autoFocus
              menuIsOpen
              openMenuOnFocus
              tagType={contentType}
              value={selected}
              onAddTag={onCreate}
              onRemoveTag={onDelete}
              onFilterOptions={onLoadOptions}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(
  withTags(EditTagButton)({ isCreate: true }),
  areContentTypeTagsEqual,
);
