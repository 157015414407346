import { gql } from "@apollo/client";

import { videoSessionDefinition } from "@/definitions/videoCallDefinitions";
import * as sharedConversationDefinitions from "./sharedConversationDefinitions";

export const contactDefinition = `{
  id
  phoneNumber
  fullName
  displayName
  email
  comment
  blastingOptOutAt
  contactTags {
    id 
    contact {
      id
    }
    contentTypeAllowedTag {
      id 
      tag {
        id 
        name
      }
    }
  }

  voiceProviderContacts {
    id
    accountId
  }

	messagingProviderContacts {
    id
    messagingProvider {
      id
      name
    }
  }
}`;

/*
  This definition of a conversation is specifically for a query in the ConversationProvider.
  The data will be passed down to the to components like ChatPanel, TopBar etc for further use.
  This should be the most verbose definition for a conversation we have
*/
export const conversationProviderConversationDefinition = `{
    id
    conversationStatus: status
    created
    resolvedAt
    isPriority
    allowedInboxView ${sharedConversationDefinitions.allowedInboxViewDefinition}
    allowedActions
    eventsTotalCount
    globalUnreadConversationMessagesCount
    assignee ${sharedConversationDefinitions.agentDefinition}
    group ${sharedConversationDefinitions.groupDefinition}
    conversationTags ${sharedConversationDefinitions.conversationTagsDefinition}
    ongoingVideoSession ${videoSessionDefinition}
    contact ${contactDefinition}
    messagingProviderAccount {
      id
      name
      messagingProvider {
        id
        name
      }
    }
    messageWindow {
      isOpen
    }
    messagingProviderContact {
      id
      accountId      
      messagingProvider {
        id
        accountIdLabel
        messagingProviderLabel
        name
      }
    }
}`;

export const CACHED_CONVERSATION_PROVIDER_CONVERSATION_FRAGMENT = gql`
  fragment CachedConversationProviderConversation on ConversationObject ${conversationProviderConversationDefinition}
`;
