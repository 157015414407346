import { all, call, put, takeLatest } from "redux-saga/effects";

import * as userActions from "@/actions/userActions";

function* unregisterServiceWorker() {
  if (!("serviceWorker" in navigator)) return;

  const serviceWorkerRegistration =
    yield navigator.serviceWorker.getRegistration();

  const subscription =
    yield serviceWorkerRegistration.pushManager.getSubscription();

  if (!subscription) return;

  yield subscription.unsubscribe();
}

function* logOut() {
  yield call(unregisterServiceWorker);
  localStorage.removeItem("token");
  yield put(userActions.logOutSuccess());
}

function* watchLogOut() {
  yield takeLatest(userActions.LOG_OUT_REQUEST, logOut);
}

export default function* userSaga() {
  yield all([watchLogOut()]);
}
