import { gql } from "@apollo/client";
import { capitalize, uniqueId } from "lodash";
import { v4 as uuidv4 } from "uuid";

import hexTransparencyEnum from "@/enums/hexTransparencyEnum";
import {
  dataObjectTypenameEnum,
  mutationPayloadTypenameEnum,
} from "@/enums/typename";
import { partialContentTypeAllowedTagDefinition } from "@/queries/tagQueries";

export const prepareTagOptions = (contentTypeAllowedTag) => {
  if (!contentTypeAllowedTag) return {};
  const { id, name } = contentTypeAllowedTag?.tag;

  return {
    value: id,
    label: name,
    conversationId: contentTypeAllowedTag?.conversationId,
    contentType: contentTypeAllowedTag?.contentType.model,
    contentTypeTagId: contentTypeAllowedTag.contentTypeTagId || null,
    contentTypeObjectId: contentTypeAllowedTag?.contentTypeObjectId,
    contentTypeAllowedTagId: contentTypeAllowedTag.id,
  };
};

export const prepareContentTypeTags = ({
  contentTypeTags = [],
  conversationId = null,
  contentType,
}) =>
  contentTypeTags.map((contentTypeTag) => ({
    contentType,
    conversationId,
    value: contentTypeTag.contentTypeAllowedTag.tag.id,
    label: contentTypeTag.contentTypeAllowedTag.tag.name,
    contentTypeTagId: contentTypeTag.id,
    contentTypeObjectId: contentTypeTag[contentType].id,
    contentTypeAllowedTagId: contentTypeTag.contentTypeAllowedTag.id,
  }));

export const generateCreateContentTypeTagResponse = ({ option }) => {
  const {
    value,
    label,
    contentType,
    contentTypeObjectId,
    contentTypeAllowedTagId,
  } = option;
  const tempId = uniqueId();
  const capitalizedContentType = capitalize(option.contentType);

  return {
    [`create${capitalizedContentType}Tag`]: {
      id: tempId,
      clientMutationId: uuidv4(),
      [`${contentType}Id`]: contentTypeObjectId,
      contentTypeAllowedTagId: contentTypeAllowedTagId,
      instance: {
        id: tempId,
        [`${contentType}`]: {
          id: contentTypeObjectId,
          __typename: dataObjectTypenameEnum[`${contentType}Object`],
        },
        contentTypeAllowedTag: {
          id: contentTypeAllowedTagId,
          tag: {
            id: value,
            name: label,
            __typename: dataObjectTypenameEnum.tagObject,
          },
          __typename: dataObjectTypenameEnum.contentTypeAllowedTagObject,
        },
        __typename: dataObjectTypenameEnum[`${contentType}TagObject`],
      },
      __typename:
        mutationPayloadTypenameEnum[`${contentType}TagMutationPayload`],
    },
  };
};

export const generateDeleteContentTypeTagResponse = ({ contentType }) => {
  const capitalizedContentType = capitalize(contentType);

  return {
    [`delete${capitalizedContentType}Tag`]: {
      ok: true,
      __typename:
        mutationPayloadTypenameEnum[
          `delete${capitalizedContentType}TagMutation`
        ],
    },
  };
};

const contentTypeTagMutationPayload = ({ contentType }) => `
  {
    id
    clientMutationId
    ${contentType}Id
    contentTypeAllowedTagId
    instance {
      id
      ${contentType} {
        id
      }
      contentTypeAllowedTag {
        ${partialContentTypeAllowedTagDefinition}
      }
    }
  }
`;

export const constuctCreateContentTypeTagMutation = ({ contentType }) => {
  const capitalizedContentType = capitalize(contentType);

  return gql`
  mutation Create${capitalizedContentType}Tag($input: Create${capitalizedContentType}TagMutationInput!) {
    create${capitalizedContentType}Tag(input: $input) ${contentTypeTagMutationPayload(
      { contentType },
    )}
  }
`;
};

export const constructUpdateContentTypeTagMutation = ({ contentType }) => {
  const capitalizedContentType = capitalize(contentType);

  return gql`
  mutation Update${capitalizedContentType}Tag($input: Update${capitalizedContentType}TagMutationInput!) {
    update${capitalizedContentType}Tag(input: $input) ${contentTypeTagMutationPayload(
      { contentType },
    )}
  }
`;
};

export const constructDeleteContentTypeTagMutation = ({ contentType }) => {
  const capitalizedContentType = capitalize(contentType);

  return gql`
  mutation Delete${capitalizedContentType}Tag($input: Delete${capitalizedContentType}TagInput!) {
    delete${capitalizedContentType}Tag(input: $input) {
      ok
    }
  }
`;
};

export const constructContentTypeTagFragment = ({ contentType }) => {
  const capitalizedContentType = capitalize(contentType);

  return gql`
    fragment ${capitalizedContentType}TagFields on ${capitalizedContentType}TagObject {
      id
      ${contentType} {
        id
      }
      contentTypeAllowedTag {
        ${partialContentTypeAllowedTagDefinition}
      }
    }
  `;
};

export const constructContentTypeTagQuery = ({ contentType }) => {
  const capitalizedContentType = capitalize(contentType);

  return gql`
    query Get${capitalizedContentType}Tags(
      $id: UUID
      $order: Int
      $${contentType}: ID
      $tenantAccount: ID
      $contentTypeAllowedTag: ID
    ) {
      ${contentType}Tags(
        id: $id
        order: $order
        ${contentType}: $${contentType}
        tenantAccount: $tenantAccount
        contentTypeAllowedTag: $contentTypeAllowedTag
      ) {
        id
        ${contentType} {
          id
        }
        contentTypeAllowedTag {
          ${partialContentTypeAllowedTagDefinition}
        }
      }
    }
  `;
};

export const updateCacheOnCreateTag = ({
  cache,
  contentType,
  contentTypeTagData,
  contentTypeObjectId,
  contentTypeTagFragment,
  contentTypeAllowedTagId,
  onUpdateTagCache,
}) => {
  const contentTypeObjectRef = cache.identify({
    id: contentTypeObjectId,
    __typename: dataObjectTypenameEnum[`${contentType}Object`],
  });

  const contentTypeTagRef = cache.identify({
    id: contentTypeTagData.id,
    __typename: contentTypeTagData.__typename,
  });

  cache.writeFragment({
    id: contentTypeTagRef,
    data: contentTypeTagData,
    fragment: contentTypeTagFragment,
  });

  onUpdateTagCache({
    isCreate: true,
    contentType,
    contentTypeTagRef,
    contentTypeTagData,
    contentTypeAllowedTagId,
  });

  cache.modify({
    id: contentTypeObjectRef,
    fields: {
      [`${contentType}Tags`]: (existing, { readField }) => {
        const tagExists = existing.some((ref) => {
          const contentTypeAllowedTagRef = readField(
            "contentTypeAllowedTag",
            ref,
          );

          return (
            readField("id", contentTypeAllowedTagRef) ===
            contentTypeAllowedTagId
          );
        });

        if (tagExists) return existing;

        return [...existing, { __ref: contentTypeTagRef }];
      },
    },
  });
};

export const updateCacheOnDeleteTag = ({
  cache,
  contentType,
  contentTypeTagId,
  contentTypeObjectId,
  onUpdateTagCache,
}) => {
  const contentTypeObjectRef = cache.identify({
    id: contentTypeObjectId,
    __typename: dataObjectTypenameEnum[`${contentType}Object`],
  });

  const contentTypeTagRef = cache.identify({
    id: contentTypeTagId,
    __typename: dataObjectTypenameEnum[`${contentType}TagObject`],
  });

  onUpdateTagCache({
    isDelete: true,
    contentTypeTagRef,
  });

  cache.modify({
    id: contentTypeObjectRef,
    fields: {
      [`${contentType}Tags`]: (existing, { readField }) => {
        const newTagRefs = existing.filter(
          (ref) => readField("id", ref) !== contentTypeTagId,
        );

        cache.evict({ id: contentTypeTagRef });
        cache.gc();

        return newTagRefs;
      },
    },
  });
};

export const getTagThemeColor = (theme, tagType) => {
  const tagColors = {
    default: {
      text: theme.palette.common.white,
      background: theme.palette.primaryBackground.darkest,
      transparentBackground: `${theme.palette.primaryBackground.darkest}${hexTransparencyEnum[0]}`,
    },
    conversation: {
      text: theme.palette.common.white,
      background: theme.palette.primaryBackground.darkest,
      transparentBackground: `${theme.palette.primaryBackground.darkest}${hexTransparencyEnum[0]}`,
    },
    contact: {
      text: theme.palette.common.white,
      background: theme.palette.secondary.main,
      transparentBackground: `${theme.palette.secondary.main}${hexTransparencyEnum[0]}`,
    },
    corporate: {
      text: theme.palette.common.white,
      background: theme.palette.tertiary.light,
      transparentBackground: `${theme.palette.tertiary.light}${hexTransparencyEnum[0]}`,
    },
  };

  return tagColors[tagType];
};
