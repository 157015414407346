import React from "react";

import { tagTypeEnum } from "@/enums/tagEnum";
import withTags from "@/hoc/withTags";
import { areContentTypeTagsEqual } from "@/utils/inboxPageMemoUtils";
import Tag from "./Tag";

const TagList = ({ tagProps }) => {
  const { selected } = tagProps;

  return selected.map((tag) => (
    <Tag key={tag.value} tag={tag} tagType={tagTypeEnum.CONVERSATION} />
  ));
};

export default React.memo(
  withTags(TagList)({ isCreate: false }),
  areContentTypeTagsEqual,
);
