import { makeStyles } from "@material-ui/core";
import React from "react";

import InvalidPhoneNumberMessage from "@/components/InboxPage/ConversationPane/SearchContact/ContactList/InvalidPhoneNumberMessage";
import { validateSearchContactPhoneNumber } from "@/utils/inboxPageUtils";

const useStyles = makeStyles((theme) => ({
  emptyPlaceholder: {
    color: theme.palette.textSecondary.main,
    textAlign: "center",
    padding: "3px",
  },

  invalidPhoneNumber: {
    padding: 0,
  },
}));

const NoOptionsMessage = (props) => {
  const classes = useStyles();

  const {
    selectProps: { inputValue },
  } = props;

  const { errorMessage } = validateSearchContactPhoneNumber(inputValue);

  if (!inputValue) return null;

  if (inputValue.length < 3)
    return (
      <InvalidPhoneNumberMessage
        ContainerProps={{
          className: classes.invalidPhoneNumber,
        }}
        message="Type at least 3 characters to begin search"
      />
    );

  if (errorMessage) {
    return (
      <InvalidPhoneNumberMessage
        ContainerProps={{
          className: classes.invalidPhoneNumber,
        }}
        message={errorMessage}
      />
    );
  }

  return <div className={classes.emptyPlaceholder}>No options found</div>;
};

export default NoOptionsMessage;
