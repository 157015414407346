import { useTheme } from "@material-ui/core/styles";
import React, { useMemo, useState } from "react";
import { components } from "react-select";

import Selector from "@/components/Selector";
import { tagTypeEnum } from "@/enums/tagEnum";
import { returnNull } from "@/utils/noopUtils";
import { getTagThemeColor } from "@/utils/tagUtils";
import Tag from "./Tag";

import BtnCloseWhite from "@/assets/images/btn-close-white.svg";

const getCustomStyles = (theme) => ({
  container: (provided, state) => {
    const { isDisabled } = state;
    return {
      ...provided,
      color: isDisabled
        ? theme.palette.grey[960]
        : theme.palette.textPrimary.main,
      width: "100%",
    };
  },

  control: (provided) => ({
    ...provided,
    width: "100%",
    minHeight: "22px",
    height: "auto",
    outline: "transparent",
    boxShadow: "unset",
    border: `1px solid ${theme.palette.primaryBorder.light}`,
    borderRadius: "2px",
    ":hover": {
      borderColor: theme.palette.primaryBorder.light,
    },
  }),

  option: (provided, state) => {
    const { isFocused, isSelected, value, options } = state;
    const isLastOption = options.slice(-1)[0].value === value;

    return {
      ...provided,
      fontSize: "1rem",
      cursor: "pointer",
      wordBreak: "break-word",
      marginBottom: isLastOption ? "0px" : "1px",
      backgroundColor: isFocused
        ? theme.palette.primaryAction.light
        : isSelected
          ? theme.palette.primaryBackground.main
          : "unset",
      ":active": {
        backgroundColor: theme.palette.primaryAction.light,
      },
      ":hover": {
        backgroundColor: theme.palette.primaryAction.light,
      },
    };
  },

  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    border: "unset",
    marginTop: "0px",
    boxShadow: "unset",
    zIndex: "1000",
  }),

  menuList: (provided) => ({
    ...provided,
    paddingTop: "unset",
    paddingBottom: "unset",
    borderRadius: "2px",
    maxHeight: "195px",
    boxShadow: `0px 3px 6px ${theme.palette.boxShadow.main}`,
    border: `0.5px solid ${theme.palette.grey[600]}`,
  }),

  valueContainer: (provided) => ({
    ...provided,
    alignContent: "center",
    height: "fit-content",
    padding: "6px 8px",
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    padding: "unset",
    height: "20px",
    borderRadius: "0px 3px 3px 0px",
    ":hover": {
      cursor: "pointer",
    },
    div: {
      height: "-webkit-fill-available",
      padding: "3px 6px 3px 4px",
      borderRadius: "0px 3px 3px 0px",
    },
  }),
});

const TagSelector = ({
  isClearable = false,
  hasDropDownIndicator = false,
  tagType = tagTypeEnum.DEFAULT,
  placeholder = "",
  onAddTag,
  onRemoveTag,
  onMenuOpen,
  onMenuClose,
  onClearTags,
  onFilterOptions,
  isTagOperationInProgress = false,
  ...props
}) => {
  const theme = useTheme();
  const {
    customStyles: newCustomStyles,
    components: otherComponents,
    ...otherProps
  } = props;

  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);

  const customStyles = { ...getCustomStyles(theme), ...newCustomStyles };

  const tagTheme = useMemo(
    () => getTagThemeColor(theme, tagType),
    [tagType, theme],
  );

  const multiValueRemoveStyle = useMemo(() => {
    const backgroundColor =
      tagTheme?.background || theme.palette.primaryBackground.darkest;

    return {
      backgroundColor,
    };
  }, [tagTheme, theme]);

  const MultiValueLabel = (props) => {
    const { data, ...otherProps } = props;

    return (
      <components.MultiValueLabel>
        <Tag
          isInSelector
          tag={data}
          tagType={tagType}
          onRemoveTag={onRemoveTag}
          isTagOperationInProgress={isTagOperationInProgress}
          {...otherProps}
        />
      </components.MultiValueLabel>
    );
  };

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <div
          style={{
            ...multiValueRemoveStyle,
            opacity: isTagOperationInProgress ? 0.8 : 1,
          }}
        >
          <BtnCloseWhite width="8px" height="10px" />
        </div>
      </components.MultiValueRemove>
    );
  };

  const handleChange = (values, selectProps) => {
    const { option, removedValue, action } = selectProps;

    if (action === "clear") {
      onClearTags();
    }

    if (option) {
      if (action === "deselect-option") {
        onRemoveTag({
          option,
        });
        return;
      }

      onAddTag({
        option,
      });
    }

    if (removedValue) {
      onRemoveTag({
        option: removedValue,
      });
    }
  };

  const handleMenuOpen = () => {
    setCurrentPlaceholder("Type to search");
    onMenuOpen && onMenuOpen();
  };

  const handleMenuClose = () => {
    setCurrentPlaceholder(placeholder);
    onMenuClose && onMenuClose();
  };

  return (
    <Selector
      isAsync
      isMulti
      isClearable={isClearable}
      placeholder={currentPlaceholder}
      customStyles={customStyles}
      components={{
        MultiValueLabel,
        MultiValueRemove,
        ...(!hasDropDownIndicator && {
          DropdownIndicator: returnNull,
        }),
        ...(!hasDropDownIndicator &&
          !isClearable && {
            IndicatorsContainer: returnNull,
          }),
        ...otherComponents,
      }}
      onChange={handleChange}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      loadOptions={onFilterOptions}
      isDisabled={isTagOperationInProgress}
      {...otherProps}
    />
  );
};

export default TagSelector;
