import { useApolloClient, useMutation } from "@apollo/client";
import { capitalize, noop } from "lodash";
import { useMemo } from "react";
import { validate as validateUuid } from "uuid";

import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { severityEnum } from "@/enums/styleVariantEnum";
import permissionEnum from "@/helpers/PermissionEnumHelper";
import * as tagQueries from "@/queries/tagQueries";
import { DEFAULT_PAGINATION_LIMIT } from "@/settings";
import { useDebounce, useValueRef } from "@/utils/hookUtils";
import { searchForAsyncSelectOptions } from "@/utils/selectUtils";
import * as tagUtils from "@/utils/tagUtils";

const withTags =
  (Component) =>
  ({ isCreate = true }) =>
  (props) => {
    const {
      contentType,
      conversationId,
      contentTypeTags,
      contentTypeObjectId = null,
      onTagsChange = noop,
      onUpdateTagCache = noop,
    } = props;

    const client = useApolloClient();

    const { onSetAppSnackbarProps } = useAppSnackbar();

    const { userPermissionsObject } = useAuthenticatedUserContext();

    const CREATE_MUTATION = tagUtils.constuctCreateContentTypeTagMutation({
      contentType,
    });
    const DELETE_MUTATION = tagUtils.constructDeleteContentTypeTagMutation({
      contentType,
    });

    const prepareContentTypeAllowedTags = (
      { contentTypeAllowedTags },
      setOptions,
    ) => {
      const tagOptions = contentTypeAllowedTags.map((tag) =>
        tagUtils.prepareTagOptions({
          ...tag,
          conversationId,
          contentTypeObjectId,
        }),
      );

      return setOptions(tagOptions);
    };

    const [createTag, { loading: isCreateTagLoading }] =
      useMutation(CREATE_MUTATION);
    const [deleteTag, { loading: isDeleteTagLoading }] =
      useMutation(DELETE_MUTATION);

    const isTagOperationInProgress = isCreateTagLoading || isDeleteTagLoading;

    const handleCreateTag = ({ option }) => {
      if (isTagOperationInProgress) return;

      const { contentType, contentTypeObjectId, contentTypeAllowedTagId } =
        option;

      const optimisticResponse = tagUtils.generateCreateContentTypeTagResponse({
        option,
      });

      const capitalizedContentType = capitalize(contentType);
      const payloadName = `create${capitalizedContentType}Tag`;
      const contentTypeTagFragment = tagUtils.constructContentTypeTagFragment({
        contentType,
      });

      createTag({
        variables: {
          input: {
            contentTypeAllowedTagId,
            [`${contentType}Id`]: contentTypeObjectId,
          },
        },
        optimisticResponse,
        update: (cache, { data }) => {
          const contentTypeTagData = data?.[payloadName]?.instance;

          tagUtils.updateCacheOnCreateTag({
            cache,
            contentType,
            contentTypeTagData,
            contentTypeObjectId,
            contentTypeTagFragment,
            contentTypeAllowedTagId,
            onUpdateTagCache,
          });
        },
      }).catch((error) => {
        onSetAppSnackbarProps({
          message: error.message,
          AlertProps: { severity: severityEnum.error },
        });
      });
    };

    const handleDeleteTag = ({ option }) => {
      if (isTagOperationInProgress) return;

      if (!userPermissionsObject.hasPermission(permissionEnum.MANAGE_TAG)) {
        onSetAppSnackbarProps({
          message: "Deleting tags require Manage Tag permission",
          AlertProps: { severity: severityEnum.error },
        });

        return;
      }

      const { contentType, contentTypeTagId, contentTypeObjectId } = option;

      /* Prevent delete attempt before create mutation is completed. */
      if (!validateUuid(contentTypeTagId)) {
        onSetAppSnackbarProps({
          message: "Please wait a moment or refresh before removing tag",
          AlertProps: { severity: severityEnum.error },
        });

        return;
      }

      const optimisticResponse = tagUtils.generateDeleteContentTypeTagResponse({
        contentType,
      });

      deleteTag({
        variables: { input: { id: contentTypeTagId } },
        optimisticResponse,
        update: (cache) => {
          tagUtils.updateCacheOnDeleteTag({
            cache,
            contentType,
            contentTypeTagId,
            contentTypeObjectId,
            onUpdateTagCache,
          });
        },
      }).catch((error) => {
        onSetAppSnackbarProps({
          message: error.message,
          AlertProps: { severity: severityEnum.error },
        });
      });
    };

    const handleLoadOptions = useDebounce({
      func: (searchTerm, setOptions) => {
        if (!isCreate) return;

        const variables = {
          contentType,
          limit: DEFAULT_PAGINATION_LIMIT,
          nameSearch: searchTerm,
          orderBy: "tag__name",
        };

        searchForAsyncSelectOptions({
          client,
          query: tagQueries.GET_CONTENT_TYPE_ALLOWED_TAGS,
          variables,
          onSuccessCallback: (data) => {
            prepareContentTypeAllowedTags(data, setOptions);
          },
          onErrorCallback: () => setOptions([]),
        });
      },
      delay: 500,
    });

    const valueRefs = useValueRef({
      onTagsChange,
      contentType,
      conversationId,
    });

    const selected = useMemo(() => {
      valueRefs.current.onTagsChange();

      const parsedContentTypeTags = tagUtils.prepareContentTypeTags({
        contentTypeTags,
        conversationId: valueRefs.current.conversationId,
        contentType: valueRefs.current.contentType,
      });

      return parsedContentTypeTags;
    }, [valueRefs, contentTypeTags]);

    return (
      <Component
        {...props}
        tagProps={{
          selected,
          onCreate: handleCreateTag,
          onDelete: handleDeleteTag,
          onLoadOptions: handleLoadOptions,
          isTagOperationInProgress: isTagOperationInProgress,
        }}
      />
    );
  };

export default withTags;
