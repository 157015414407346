import JSZip from "jszip";
import { all, call, takeEvery } from "redux-saga/effects";

import * as previewActions from "@/actions/previewActions";

const requestFile = async (src) => {
  try {
    const response = await fetch(src).then((response) => response.blob());
    return { response };
  } catch (error) {
    return { error };
  }
};

function* getFile({ file, zip, isMultiFileDownload }) {
  const { src, fileName } = file;
  const { response } = yield requestFile(src);
  if (response) {
    /* Add to zip file if its part of multi file download */
    if (isMultiFileDownload) {
      zip.file(fileName, response);
      return;
    }

    /* Download directily if it's a single file download */
    const { saveAs } = yield import("save-as");
    saveAs(response, fileName);
  }

  /* TODO: Potentially handle error ? */
}

function* downloadFiles({ files }) {
  const isMultiFileDownload = files.length > 1;
  const zip = new JSZip();
  const zipFilename = "sharedMedia.zip";
  yield all(
    files.map((file) => call(getFile, { file, zip, isMultiFileDownload })),
  );

  if (isMultiFileDownload) {
    const { saveAs } = yield import("save-as");
    /* Generate zip file and download it */
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, zipFilename);
    });
  }
}

function* watchDownload() {
  yield takeEvery(previewActions.DOWNLOAD_FILES, downloadFiles);
}

export default function* downloadSaga() {
  yield all([watchDownload()]);
}
