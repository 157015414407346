import { delay, race, take, takeEvery } from "redux-saga/effects";

import * as conversationActions from "@/actions/conversationActions";
import * as hookActions from "@/actions/hookActions";
import { convertObjectToSnakeCase } from "@/utils/commonUtils";

const getPreludeAction = ({ watchAction }) => {
  if (watchAction === hookActions.MESSAGE_SENT) {
    return conversationActions.SEND_CONVERSATION_MESSAGE;
  }

  return null;
};

/*
  Prelude action will delay cancellation until a 5s timeout passes, sample
  use is to prevent cancellation when API request has already been submitted,
  but we don't have our desired action yet.
*/
function* watchCancellation({ watchAction, cancelActions }) {
  const preludeAction = getPreludeAction({ watchAction });

  if (!preludeAction) {
    const cancel = yield take(cancelActions);
    return cancel;
  } else {
    const { prelude, cancel } = yield race({
      prelude: take(preludeAction),
      cancel: take(cancelActions),
    });

    if (cancel) {
      return cancel;
    }

    if (prelude) {
      yield delay(5000);
      return { type: hookActions.WATCH_ACTION_TIMEOUT };
    }
  }
}

function* observeHook({
  extensionAppId,
  watchEvent,
  cancelEvents,
  data,
  runHook,
}) {
  const watchAction = hookActions.getEventHookActionType(watchEvent);
  const cancelActions = cancelEvents.map(hookActions.getEventHookActionType);

  const { success } = yield race({
    success: take(watchAction),
    cancel: watchCancellation({ watchAction, cancelActions }),
  });

  if (success) {
    const payload = success.data
      ? JSON.stringify(convertObjectToSnakeCase(success.data))
      : null;

    yield runHook({
      variables: {
        input: {
          extensionAppId,
          data,
          event: watchEvent,
          payload,
        },
      },
    });
  }
}

function* watchHookRegistration() {
  yield takeEvery(hookActions.REGISTER_HOOK, observeHook);
}

export default function* hookSaga() {
  yield* [watchHookRegistration()];
}
