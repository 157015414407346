import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import InfoIcon from "@/assets/images/icon-info-blue.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },

  infoIcon: {
    alignSelf: "center",
    margin: "3px 10px 0px 5px",
    "& path": {
      fill: theme.palette.alert.main,
    },
  },

  message: {
    lineHeight: "1.5rem",
    fontSize: "0.85rem",
    color: theme.palette.alert.main,
  },
}));

const InvalidPhoneNumberMessage = ({
  ContainerProps = {},
  message = "Invalid phone number",
}) => {
  const classes = useStyles();

  const { className, ...otherContainerProps } = ContainerProps;

  return (
    <div
      className={clsx(classes.container, className)}
      {...otherContainerProps}
    >
      <div className={classes.infoIcon}>
        <InfoIcon />
      </div>

      <div className={classes.message}>{message}</div>
    </div>
  );
};

export default InvalidPhoneNumberMessage;
