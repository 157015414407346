import { gql } from "@apollo/client";

export const partialContentTypeAllowedTagDefinition = `
  id
  tag {
    id
    name
  }
`;

const contentTypeDefinition = `{
  id
  model
  appLabel
}`;

export const GET_CONTENT_TYPE_ALLOWED_TAGS = gql`
  query ContentTypeAllowedTags(
    $id: ID
    $tag: ID
    $limit: Int
    $offset: Int
    $orderBy: String
    $contentType: String
    $nameSearch: String
  ) {
    contentTypeAllowedTags(
      id: $id
      tag: $tag
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      contentType: $contentType
      nameSearch: $nameSearch
    ) {
      ${partialContentTypeAllowedTagDefinition}
      contentType ${contentTypeDefinition}
    }
  }
`;
